<template>
  <v-container>
    <perseu-card title="Sintomas">
      <v-btn color="secondary" slot="title-right" @click="create">
        Novo Sintoma
      </v-btn>
      <v-flex slot="content">
        <filters-panel class="mb-3">
          <v-row>
            <v-col cols="12" md="2">
              <v-switch v-model="query.active" label="Ativos"></v-switch>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="query.name"
                label="Nome"
                @keyup.enter.native="list"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn color="secondary" @click="list"> Buscar </v-btn>
          </v-row>
        </filters-panel>
        <v-data-table :items="items" :headers="headers" class="elevation-1">
          <template v-slot:item.actions="props">
            <v-btn small icon @click="edit(props.item)" color="secondary">
              <v-icon small>edit</v-icon>
            </v-btn>
            <v-btn
              small
              icon
              v-if="props.item.active"
              @click="activeOrDeactivate(props.item, false)"
              color="secondary"
            >
              <v-icon small>delete</v-icon>
            </v-btn>
            <v-btn
              small
              v-else
              icon
              @click="activeOrDeactivate(props.item, true)"
              color="secondary"
            >
              <v-icon small>check</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </perseu-card>
    <v-dialog v-model="dialog" v-if="dialog" width="600px">
      <form-symptoms
        @create="createdForm"
        @update="updatedForm"
        :symptom="item"
      ></form-symptoms>
    </v-dialog>
  </v-container>
</template>

<script>
import * as service from "@/services/symptoms-service";
export default {
  components: {
    "form-symptoms": () => import("@/components/Symptoms/Form"),
  },
  data: () => ({
    headers: [
      { text: "Nome", value: "name" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "start",
        width: 200,
      },
    ],
    items: [],
    item: {},
    dialog: false,
    query: {
      active: true,
    },
  }),
  methods: {
    async activeOrDeactivate(item, option) {
      try {
        this.$store.dispatch("loading/openDialog");
        item.active = option;
        await service.update(item);
        const index = this.findIndexItem(item);
        this.items.splice(index, 1);
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async list() {
      try {
        this.$store.dispatch("loading/openDialog");
        let response = await service.find(this.query);
        this.items = response.data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async create() {
      this.item = {};
      this.dialog = true;
    },
    edit(item) {
      this.item = item;
      this.dialog = true;
    },
    createdForm(item) {
      this.items.push(item);
      this.dialog = false;
    },
    updatedForm() {
      this.dialog = false;
    },
    findIndexItem(itemParam) {
      return this.items.findIndex((item) => item.id === itemParam.id);
    },
  },
  created() {
    this.list();
  },
};
</script>

<style></style>
